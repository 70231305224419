import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user.model';
import {SAFETOTAL_WEB_API} from './safetotal-web.api'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  login(user: User){
    return this.http.post(`${SAFETOTAL_WEB_API}/api/auth`, user);
  }

  createOrUpdate(user: User){
    if (user.id != null && user.id != '') {
      return this.http.put(`${SAFETOTAL_WEB_API}/api/user`, user);
    } else {
      user.id = null;
      return this.http.post(`${SAFETOTAL_WEB_API}/api/user`, user);
    }
  }

  findAll(page: number, count: number){
    return this.http.get(`${SAFETOTAL_WEB_API}/api/user/${page}/${count}`);
  }

  findById(id: string){
    return this.http.get(`${SAFETOTAL_WEB_API}/api/user/${id}`);
  }

  delete(id: string){
    return this.http.delete(`${SAFETOTAL_WEB_API}/api/user/${id}`);
  }
}
