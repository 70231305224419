import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-form-validate',
  templateUrl: './button-form-validate.component.html',
  styleUrls: ['./button-form-validate.component.css']
})
export class ButtonFormValidateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
