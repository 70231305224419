import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-connect',
  templateUrl: './pre-connect.component.html',
  styleUrls: ['./pre-connect.component.css']
})
export class PreConnectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
