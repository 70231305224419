import { SharedService } from 'src/app/services/shared.service';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate{
    
    public shared : SharedService;

    constructor(private router : Router){
        this.shared = SharedService.getInstance();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
            if (this.shared.isLoggedIn()) {
               return true;
            }
            this.router.navigate(['/login']);
            return false;
    }
    

}