import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { User } from 'src/app/model/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  shared: SharedService;
  constructor() { 
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
  }

  exit(){
    this.shared.user = new User('', '', '', '', '');
    window.location.href = '/login';
    window.location.reload();
  }

  onClickCamerasPrivadasMenu(state: string){
    this.shared.autoExpand = true;
    this.shared.mainMenuExpanded = false;
    this.shared.routerFluidActive = state;
  }
  
  onclickHomeMenu(state: string){
    this.shared.autoExpand = false;
    this.shared.mainMenuExpanded = true;
    this.shared.routerFluidActive = state;
  }

  onClickPainelControleMenu(state: string){
    this.shared.autoExpand = false;
    this.shared.mainMenuExpanded = true;
    this.shared.routerFluidActive = state;
  }
}
