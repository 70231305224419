import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cameras-publicas',
  templateUrl: './cameras-publicas.component.html',
  styleUrls: ['./cameras-publicas.component.css']
})
export class CamerasPublicasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
