import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-close-content',
  templateUrl: './close-content.component.html',
  styleUrls: ['./close-content.component.css']
})
export class CloseContentComponent implements OnInit {

  shared: SharedService;
  constructor() { 
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
  }

  closeContent(){
    this.shared.mainMenuExpanded = true;
    this.shared.autoExpand = false;
    this.shared.routerFluidActive = "cameras-publicas";
  }
}
 