import { AuthGuard } from './components/security/auth.guard';
import { AuthInterceptor } from './components/security/auth.interceptor';
import { HttpClient } from 'selenium-webdriver/http';
import { SharedService } from './services/shared.service';
import { UserService } from './services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/security/login/login.component';
import { routes } from './app.routes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CamerasPrivadasComponent } from './components/cameras-privadas/cameras-privadas.component';
import { CamerasPublicasComponent } from './components/cameras-publicas/cameras-publicas.component';
import { PainelControleComponent } from './components/painel-controle/painel-controle.component';
import { CadastrarUsuarioComponent } from './components/cadastrar-usuario/cadastrar-usuario.component';
import { ButtonFormValidateComponent } from './components/button-form-validate/button-form-validate.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { CloseContentComponent } from './components/close-content/close-content.component';
import { PreConnectComponent } from './components/pre-connect/pre-connect.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    CamerasPrivadasComponent,
    CamerasPublicasComponent,
    PainelControleComponent,
    CadastrarUsuarioComponent,
    ButtonFormValidateComponent,
    MapViewComponent,
    CloseContentComponent,
    PreConnectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    routes
  ],
  providers: [
    UserService, 
    SharedService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
