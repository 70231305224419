import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/model/user.model';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cameras-privadas',
  templateUrl: './cameras-privadas.component.html',
  styleUrls: ['./cameras-privadas.component.css']
})
export class CamerasPrivadasComponent implements OnInit {
  
  @ViewChild("form")
  form: NgForm

  user = new User('', '', '', '', '');
  shared: SharedService;
  message: {};
  classCss: {};

  constructor(
    private userService: UserService,
    private route: ActivatedRoute
  ) { 
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
  }

  showPreConnect(state: string){
    this.shared.mainMenuExpanded = false;
    this.shared.routerFluidActive = state;
  }
}
