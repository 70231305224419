import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  shared: SharedService;
  constructor() { 
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    
  }

  onClickCamerasPrivadasMenu(state: string){
    this.shared.routerFluidActive = state;
  }

  onMenuEnter(){
    if (this.shared.autoExpand) {
      this.shared.mainMenuExpanded = true;
    }
    
  }

  onMenuLeave(){
    if (this.shared.autoExpand) {
      this.shared.mainMenuExpanded = false;
    }
   
  }
}
